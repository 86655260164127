<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
          v-if="can_list"
          id="unit-table"
          ref="data-grid"
          width="100%"
          :show-borders="true"
          :data-source="dataUnit"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :hover-state-enabled="true"
          key-expr="id"
          exporting="exportGrid"
          @exporting="onExporting"
        >
          <DxColumn data-field="id" caption="ID" />
          <DxColumn data-field="symbol" :caption="$t('table.symbol')" />
          <DxColumn data-field="name" :caption="$t('table.name')" />

          <DxFilterRow :visible="true" />
          <DxSearchPanel :visible="true" />
          <DxColumnFixing :enabled="true" />
          <DxColumnChooser :enabled="true" />
          <DxScrolling mode="standard" />
          <DxGrouping :context-menu-enabled="true" />
          <DxGroupPanel :visible="true" />
          <DxPaging :page-size="20" />

          <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="showPageSizeSelector"
            :show-info="showInfo"
            :show-navigation-buttons="showNavButtons"
          />
          <DxExport
            :enabled="true"
            :visible="true"
            :allow-export-selected-data="false"
          />
          <DxEditing
            :allow-updating="can_edit"
            :allow-deleting="can_delete"
            :allow-adding="can_create"
            mode="row"
            :select-text-on-edit-start="true"
            :use-icons="true"
          />
        </DxDataGrid>
        <h1 v-else>{{$t("noPermission")}}</h1>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios';
import { BCard } from 'bootstrap-vue';
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';

export default {
  components: {
    BCard,
    DxEditing,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport,
    DxPager
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataUnit: [],
        displayMode: 'full',
      dataLoading: true,
      error: ''
    };
  },
  async mounted() {
    await this.getPageAuths();
    await this.getUnitData();
  },
  methods: {
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', { page: 'Unit' });
      this.can_list = pageAuths.data.can_list;
    },
    async getUnitData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-Unit');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: 'Arial', size: 12 };
          excelCell.alignment = { horizontal: 'left' };
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Fama-Unit.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';
.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
